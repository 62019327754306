.preloader {
	padding: 80px 0;
	background-color: #f5f6f7;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.preloader__title {
  font-family: 'Roboto Slab', Times, serif;
  font-weight: 400;
  font-size: 26px;
  line-height: 30px;
  margin-bottom: 0;
}

.preloader__text {
	font-family: 'Roboto', Helvitica, sans-serif;
	font-weight: 400;
	font-size: 18px;
	line-height: 24px;
	color: #B6BCBF;
	margin: 24px 0 0 0;
	max-width: 250px;
  text-align: center;
}

.preloader__circle {
	display: block;
	width: 74px;
	height: 74px;
	border: 1px solid #1a1b22;
	border-bottom-color: #888;
	border-radius: 50%;
	margin: auto;
	animation: spin .75s infinite linear;
}

.preloader__nothingFound {
	display: block;
	width: 80px;
	height: 80px;
	margin: auto;
  background-image: url('../../images/not-found.svg');
}

@keyframes spin {
	100% {
		transform: rotate(360deg);
	}
}