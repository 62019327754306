@import url('../../vendor/normalize.css');
@import url('../../vendor/fonts/fonts.css');

.app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  background-color: #fff;
  font-family: 'Roboto', 'Helvetica', sans-serif;
  color: #1A1B22;
  min-width: 320px;
}
