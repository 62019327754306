.card {
  background-color: white;
  list-style-type: none;
  max-width: 400px;
  height: 576px;
  border-radius: 16px;
  position: relative;
  display: flex;
  flex-direction: column;
}

.card:hover {
  box-shadow: 0px 6px 10px 0px rgba(8, 29, 45, 0.1);
}

.card__image {
  width: 100%;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  min-height: 272px;
  object-fit: cover;
  background-position: center center;
  border-bottom: 1px solid rgba(59, 74, 116, 0.1);
}

.card__image::before {
  content: ' ';
  background-size: cover;
  background-image: url(../../images/no-image.png);
  min-height: 272px;
  display: block;
  background-position: center center;
}

.card__text-wrapper {
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: 304px;
  text-decoration: none;
}

.card__pubDate {
  font-family: 'Source Sans Pro', Helvetica, sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  margin: 0 0 12px 0;
  padding: 0;
  color: #b6bcbf;
}

.card__title {
  font-family: 'Roboto Slab', Helvetica, sans-serif;
  font-weight: 400;
  font-size: 26px;
  line-height: 30px;
  margin: 0 0 16px 0;
  padding: 0;
  color: #1a1b22;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  white-space: normal;
  height: 60px;
}

.card__description {
  font-family: 'Roboto', Helvetica, sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  margin: 0 0 18px 0;
  padding: 0;
  color: #1a1b22;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  white-space: normal;
}

.card__source {
  font-family: 'Roboto Slab', Helvetica, sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  margin: 0;
  padding: 0;
  color: #b6bcbf;
  text-transform: uppercase;
}

.card__icon_trash {
  background-image: url('../../images/trash.svg');
  width: 40px;
  height: 40px;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
  border: none;
  position: absolute;
  border-radius: 10px;
  top: 24px;
  right: 24px;
  background-color: transparent;
  transition: all 0.3s ease 0s;
  box-shadow: 0px 10px 22px rgba(59, 74, 116, 0.1);
}

.card__icon_trash:hover {
  background-image: url('../../images/trash-hover.svg');
}

.card__icon_save {
  background-image: url('../../images/save.svg');
  width: 40px;
  height: 40px;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
  border: none;
  position: absolute;
  top: 24px;
  right: 24px;
  background-color: transparent;
  transition: all 0.3s ease 0s;
  box-shadow: 0px 10px 22px rgba(59, 74, 116, 0.1);
}

.card__icon_save:hover {
  background-image: url('../../images/save-hover.svg');
}

.card__icon_save:focus {
  outline: none;
}

.card__icon_save_true {
  background-image: url('../../images/save-active.svg');
  width: 40px;
  height: 40px;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
  border: none;
  position: absolute;
  top: 24px;
  right: 24px;
  background-color: transparent;
  transition: all 0.3s ease 0s;
  box-shadow: 0px 10px 22px rgba(59, 74, 116, 0.1);
}

.card__icon_save_true:focus {
  outline: 0;
}

.card__hover-wrapper {
  box-sizing: border-box;
  padding: 0px 26px;
  height: 40px;
  position: absolute;
  top: 24px;
  right: 69px;
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid rgba(59, 74, 116, 0.2);
  box-shadow: 10px 10px 10px rgba(59, 74, 116, 0.2);
  z-index: 3;
}

.card__hover-text {
  font-size: 12px;
  line-height: 14px;
  font-family: 'Roboto', Helvetica, sans-serif;
  font-weight: 500;
}

.card__keyword-wrapper {
  padding: 0px 22px;
  height: 40px;
  position: absolute;
  top: 24px;
  left: 24px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 10px 22px rgba(59, 74, 116, 0.1);
  display: flex;
  align-items: center;
}

.card__keyword {
  font-size: 14px;
  line-height: 16px;
  font-family: 'Roboto', Helvetica, sans-serif;
  font-weight: 500;
}

@media screen and (max-width: 768px) {
  .card{
    min-width: 224px;
    height: 420px;
  }

  .card__image {
    min-height: 150px;
  }

  .card__image::before {
    min-height: 150px;
  }

  .card__icon_save_true {
    right: 8px;
    top: 8px;
  }

  .card__icon_save {
    right: 8px;
    top: 8px;
  }

  .card__icon_trash  {
    right: 8px;
    top: 8px;
  }
  .card__text-wrapper {
    padding: 16px;
  }

  .card__hover-wrapper {
    top: 8px;
  }

  .card__keyword-wrapper {
    top: 8px;
  }

  .card__pubDate {
    margin-bottom: 0;
  }

  .card__title {
    font-size: 22px;
    line-height: 24px;
    margin: 0 0 8px 0;
    -webkit-line-clamp: 3;
    height: 72px;
  }

  .card__description {
    -webkit-line-clamp: 4;
  }
}