.footer {
  max-width: 1232px;
  height: 80px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 auto;
  justify-content: space-between;
  padding: 0 40px;
}

.footer__copyright {
  margin: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #B6BCBF;
}

.footer__nav {
  display: flex;
  align-items: flex-end;
  padding: 0;
  margin: 0;
}

.footer__nav-text-list {
  display: flex;
  flex-direction: row;
  padding: 0;
}

.footer__nav-icon-list {
  display: flex;
  flex-direction: row;
  padding: 0;
}

.footer__nav-text-wrapper {
  display: flex;
  flex-direction: row;
}

.footer__nav-text {
  padding: 0 25px;
  list-style-type: none;
}

.footer__nav-link {
  text-decoration: none;
  font-size: 18px;
  line-height: 24px;
  color: #1a1b22;
}

.footer__nav-icon-wrapper {
  display: flex;
  flex-direction: row;
}

.footer__nav-icon {
  height: 20px;
  padding-left: 28px;
  color: #1a1b22;
  list-style-type: none;
}

@media screen and (max-width: 800px) {
  .footer__nav-text {
    padding: 0 10px;
  }
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .footer {
    padding: 128px 20px 0 20px;
    flex-direction: column-reverse;
  }

  .footer__nav {
    justify-content: space-between;
    width: 100%;
    align-items: flex-start;
    padding-bottom: 38px;
  }

  .footer__nav-text-wrapper {
    height: 74px;
  }

  .footer__copyright {
    text-align: center;
    padding-bottom: 20px;
  }

  .footer__nav-text {
    padding: 0;
    line-height: 48px;
  }

  .footer__nav-text-list {
    flex-direction: column;
    justify-content: space-evenly;
  }

  .footer__nav-icon-list {
    flex-direction: row;
  }

}