.userInfo {
  max-width: 1232px;
  padding: 40px 40px 56px 40px;
  margin: 0 auto;
}

.userInfo__container {
  max-width: 530px;
}

.userInfo__title {
  font-family: 'Roboto', Helvetica, sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #1a1b2280;
  margin: 0 0 28px 0;
}

.userInfo__stats {
  font-family: 'Roboto Slab', Helvetica, sans-serif;
  font-weight: 400;
  font-size: 40px;
  line-height: 46px;
  color: #1a1b22;
  margin: 0 0 30px 0;
}

.userInfo__keywords {
  font-family: 'Roboto', Helvetica, sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #1a1b22;
  margin: 0;
}

.userInfo__keywords_strong {
  font-weight: 700;
}

@media screen and (max-width: 768px) {
  .userInfo {
    padding: 24px 40px 32px 40px;
  }
  .userInfo__title {
    font-size: 14px;
    margin: 0 0 16px 0;
  }

  .userInfo__stats {
    font-size: 30px;
    line-height: 34px;
    max-width: 336px;
    margin: 0 0 16px 0;
  }
}

@media screen and (min-width: 320px) and (max-width: 768px) {
  .userInfo {
    padding-top: 8px;
  }

  .userInfo__title {
    font-size: 12px;
  }

  .userInfo__stats {
    margin: 0 0 50px 0;
  }
}