.header {
  z-index: 2;
  height: 80px;
  border-bottom: 1px solid #D1D2D6;
  width: 100%;
  position: relative;
}

.header__contents {
  max-width: 1232px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  padding: 0 40px
}

.header__title {
  color: #fff;
  font-family: 'Roboto Slab', Helvetica, sans-serif;
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
}

.header__nav-container {
  display: flex;
  flex-direction: row;
  height: 80px;
  margin: 0;
  align-items: center;
}

.header__nav-link-container {
  list-style: none;
}

.header__nav-link {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 16px;
  height: 80px;
  padding: 0 20px;
  color: #fff;
  line-height: 24px;
  font-size: 18px;
  text-decoration: none;
  line-height: 22px;
  font-weight: 500;
  list-style: none;
  cursor: pointer;
}

.header__nav-link_active {
  border-bottom: 3px solid #fff;
  padding-top: 3px;
}

.header__nav-link_alt_active {
  border-bottom: 3px solid #1a1b22;
  padding-top: 3px;
}

.header__nav-button {
  padding: 0 15px 0 20px;
  height: 48px;
  border: 1px solid #fff;
  border-radius: 100px;
  background: none;
  color: #fff;
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: 16px;
  text-transform: capitalize;
}

.header__nav-button:focus {
  outline: 0;
}

.signin {
  padding: 0 55px;
}

.header__icon_logout {
  width: 24px;
  height: 24px;
  margin: 0 0 0 15px;
}

.header__color_alt {
  color: #1a1b22;
  border-color: #1a1b22;
}

.header__sidebar {
  visibility: hidden;
}

.header__sidebar-button {
  display: none;
}

@media screen and (max-width: 700px) {
  .header {
    height: 56px;
  }

  .header__contents {
    padding: 5px 16px 0 16px;
  }

  .header__title {
    font-size: 16px;
    line-height: 24px;
  }

  .header__sidebar-button {
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent;
    width: 24px;
    height: 24px;
    display: block;
  }

  .header__sidebar_open {
    background-image: url('../../images/open-menu-white.svg');
  }

  .header__sidebar_open-alt {
    background-image: url('../../images/open-menu-black.svg');
  }

  .header__sidebar_closed {
    background-image: url('../../images/close-menu-white.svg');
  }

  .header__sidebar_closed-alt {
    background-image: url('../../images/close-menu-black.svg');
  }

  .header__nav-container {
    display: none;
  }

  .header__nav-link-container {
    height: 56px;
    display: flex;
    align-items: center;
    margin-top: 16px;
  }

  .header__nav-link {
    padding: 0;
  }

  .header__nav-link_active {
    border-bottom: none;
  }

  .header__nav-button {
    margin: 22px 16px 24px 16px;
    padding: 0;
  }

  .header__sidebar-button {
    height: 24px;
    width: 24px;
    background-color: transparent;
    border: none;
    background-position: center center;
    background-repeat: no-repeat;
    padding: 0;
    z-index: 100;
  }

  .header__sidebar {
    position: fixed;
    display: flex;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    align-items: center;
    justify-content: center;
    opacity: 0;
    visibility: hidden;
    padding: 0px;
    transition: visibility 1s, opacity 0.6s;
    user-select: none;
    pointer-events: none;
    z-index: 10;
  }

  .header__sidebar-container {
    width: 100%;
    /* z-index: 15; */
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 0;
    top: 57px;
    background-color: #1a1b22;
    margin: 0;
  }

  .header__sidebar_opened {
    visibility: visible;
    opacity: 1;
    transition-delay: 0s;
    pointer-events: all;
  }
}