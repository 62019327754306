.author {
  max-width: 1232px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 80px auto;
  padding: 0 40px;
}

.author__text-container {
  max-width: 600px;
}

.author__title {
  font-family: 'Roboto Slab', Helvetica, sans-serif;
  font-size: 40px;
  line-height: 46px;
  font-weight: 400;
  color: #1A1B22; 
}

.author__body {
  font-family: 'Roboto', Helvetica, sans-serif;
  font-size: 18px;
  line-height: 24px;
  color: #1A1B22; 
}

.author__image-container {
  position: relative;
  max-width: 250px;
  max-height: 250px;
  margin-right: 56px;
}

.author__image {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

@media screen and (max-width: 768px) {
  .author {
    margin: 40px auto;
  }


  .author__image-container {
    width: 232px;
    height: 232px;
    margin-right: 32px;
    box-sizing: border-box;
  }

  .author__text-container {
    max-width: 424px;
  }

  .author__title {
    font-size: 30px;
    line-height: 40px;
  }
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .author {
    padding: 0 20px;
    flex-direction: column;
  }

  .author__image-container {
    width: 272px;
    height: 272px;
    margin-right: 0;
  }
}