.cardList {
  background-color: #f5f6f7;
  padding: 0 40px;
}

.cardList_hidden {
  display: none;
}

.cardList__title {
  font-family: 'Roboto Slab', Helvetica, sans-serif;
  font-size: 40px;
  line-height: 46px;
  font-weight: 400;
  margin: 0 0 64px 0;
  color: #1A1B22; 
}

.cardList__section-wrapper {
  max-width: 1232px;
  margin: 0 auto;
  padding: 80px 0;
  display: flex;
  flex-direction: column;
}

.cardList__card-wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  padding: 0;
}

.cardList__button {
  align-self: center;
  margin-top: 64px;
  width: 288px;
  height: 64px;
  border-radius: 80px;
  border: none;
  background-color: #fff;
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #1a1b22;
  cursor: pointer;
  transition: all 0.3s ease 0s;
}

.cardList__button:hover {
  background-color: #e8e8e8;
}

.cardList__button:active {
  outline: 0;
}

@media screen and (max-width: 768px) {
  .cardList__section-wrapper {
    padding: 40px 0;
  }

  .cardList__card-wrapper {
    grid-row-gap: 8px;
    grid-column-gap: 8px;
  }

  .cardList__title {
    margin-bottom: 32px;
    font-size: 30px;
    line-height: 34px;
  }

  .cardList__button {
    margin-top: 16px;
  }
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .cardList {
    padding: 0 20px;
  }
  .cardList__card-wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 500px) {
  .cardList__card-wrapper {
    grid-template-columns: repeat(1, 1fr);
    margin: 0 auto;
  }
}
