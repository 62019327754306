.search {
  background: linear-gradient(0deg,rgba(0,0,0,.4) 0%, rgba(0,0,0,.4) 100%), url('../../images/hand-with-cellphone.jpg') no-repeat center;
  background-size: cover;
  background-position: center center;
  padding-bottom: 80px;
}

.search__container {
  max-width: 608px;
  margin: 80px auto 0 auto;
  padding: 0 20px;
}

.search__title {
  font-family: 'Roboto Slab', Times, serif;
  color: #fff;
  font-size: 60px;
  line-height: 64px;
  font-weight: 400;
  margin: 0;
  padding-bottom: 32px;
}

.search__subtitle {
  font-family: 'Roboto', Helvetica, sans-serif;
  color: white;
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  margin: 0;
}

.search__input-wrapper {
  display: grid;
  grid-template-rows: 64px;
  grid-template-columns: 440px 168px;
  margin-top: 88px;
  border-radius: 100px;
  position: relative;
}

.search__input {
  grid-column: 1 / 3;
  grid-row: 1/1;
  z-index: 0;
  border: 1px solid #D1D2D6;
  backdrop-filter: blur(80px);
  background-color: #fff;
  border-radius: 100px;
  padding-left: 24px;
  box-sizing: border-box;
}

.search__input:focus {
  border: 2px solid #2f71e5;
  border-radius: 100px;
  outline: none;
}

.search__button {
  grid-column: 2;
  grid-row: 1 /1;
  border: 1px solid #2F71e5;
  background-color: #2F71E5;
  border-radius: 100px;
  color: #fff;
  font-family: 'Roboto', Helvetica, sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  cursor: pointer;
  transition: all 0.3s ease 0s;
  z-index: 1;
}

.search__button:hover {
  background-color: #347EFF;
}

.search__button:active {
  background-color: #2A65CC;
}

.search__button:focus {
  outline: 0;
}

.search__error {
  border-bottom: none;
  color: red;
  font-size: 12px;
  line-height: 15px;
  position: absolute;
  top: 13px;
  right: 180px;
  z-index: 10;
}

@media screen and (max-width: 768px) {
  .search__container {
    max-width: 448px;
  }

  .search__title {
    font-size: 36px;
    line-height: 40px;
  }

  .search__input-wrapper {
    max-width: 448px;
    z-index: 0;
  }

  .search__error {
    top: 8px;
    right: 25px;
  }
}

@media screen and (min-width: 320px) and (max-width: 768px) {
  .search__input-wrapper {
    grid-template-rows: 56px 56px;
    grid-template-columns: 100%;
    grid-gap: 16px;
    margin: 58px auto 0 auto;
  }

  .search__input {
    grid-column: 1;
    grid-row: 1;
    z-index: 0;
  }

  .search__button {
    grid-column: 1;
    grid-row: 2;
  }
}