@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src: url(./inter-400-regular.woff), url(./inter-400-regular.woff2);
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  src: url(./inter-500.woff), url(./inter-500.woff2);
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url(./roboto-400-regular.woff), url(./roboto-400-regular.woff2);
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url(./roboto-500.woff), url(./roboto-500.woff2);
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url(./roboto-700.woff), url(./roboto-700.woff2);
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  src: url(./roboto-900.woff), url(./roboto-900.woff2);
  font-display: swap;
}

@font-face {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 400;
  src: url(./roboto-slab-400-regular.woff), url(./roboto-slab-400-regular.woff2);
  font-display: swap;
}

@font-face {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 700;
  src: url(./roboto-slab-700.woff), url(./roboto-slab-700.woff2);
  font-display: swap;
}